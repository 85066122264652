import Container from 'components/container';
import HorizontalScroll from 'react-scroll-horizontal';
import styled, { css } from 'styled-components';
import { fixedSizes } from 'styled/constants';
import PortfolioItem from 'pages/portfolio/components/item';
import FadeInSection from 'components/FadeInSection';
import MediaBreakpoints from '../../styled/mediaBreakpoints';

export const PortfolioStyle = css`
  .portfolio {
    &__carousel {
    margin-top: 3rem;
    @media ${MediaBreakpoints.largeUp} {
        margin-top: 5.25rem;
      }
      @media ${MediaBreakpoints.extraLargeUp} {
        margin-top: 6rem;
      }
    }
  }
`;

export const StyledContainer = styled(Container)`
  height: calc(100% - ${fixedSizes.headerHeight});
  max-width: 100%;
  position: static;
  width: 100vw;
  @media ${MediaBreakpoints.largeUp} {
    height: calc(100% - ${fixedSizes.headerHeightLg});
    left: 0;
    position: absolute;
    top: ${fixedSizes.headerHeightLg};
  }
`;

export const StyledMobileWrapper = styled(Container)`
  display: block;
  @media ${MediaBreakpoints.largeUp} {
    display: none;
  }
  `;

export const StyledMobileHeroContainer = styled(Container)`
  height: calc(100% - ${fixedSizes.headerHeight});
  @media ${MediaBreakpoints.largeUp} {
    height: calc(100% - ${fixedSizes.headerHeightLg});
  }
`;

export const StyledHorizontalWrapper = styled(HorizontalScroll)`
  display: none;
  scroll-behavior: smooth;
  @media ${MediaBreakpoints.largeUp} {
    display: block;
    padding-left: 120px;
  }
  &.home-included {
    @media ${MediaBreakpoints.largeUp} {
      padding-left: 5vw;
    }
  }
  >div {
    align-items: center;
    gap: 20vw;
    padding-right: 10vw;
    @media ${MediaBreakpoints.largeUp} {
      gap: 34px;
    }
  }
  /* Workaround for mobile touch scrolling. */
  @media (hover: none), (pointer:coarse), (-moz-touch-enabled: 1) {
    overflow-x: overlay !important;
    overflow-y: hidden !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &>div {
        transform: none !important;
        position: relative;
    }

    &::-webkit-scrollbar {
        display: none !important;
    }
  }
`;
export const StyledItem = styled(PortfolioItem)`
  
  .portfolio-item {
    &__image {
      display: block;
      margin-bottom: 1rem;
      max-height: 379px;
      object-fit: contain;
      @media ${MediaBreakpoints.mediumUp} {
        max-width: 100%;
        min-height: 379px;
        width: 379px
      }
    }
    &__subheading {
      width: 100%;
    }
  }
  `;

export const StyledItemWrapper = styled(FadeInSection)`
  height: auto;
  margin: 5vh auto;
  max-width: 379px;
  width: 80vw;
  
  @media ${MediaBreakpoints.largeUp} {
    height: 100%;
    margin: 0;
    max-height: 500px;
  }
`;

export const StyledHomeDesktopWrapper = styled(Container)`
`;

export const StyledHomeMobileWrapper = styled(Container)`
  height: calc(100vh - ${fixedSizes.headerHeight});
`;
