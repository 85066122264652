import styled, { css } from 'styled-components';
import Container from 'components/container';
import MediaBreakpoints from '../../styled/mediaBreakpoints';

export const ImagesWrapper = styled(Container)`
  height: auto;
  width: 100%;
  @media ${MediaBreakpoints.mediumUp} {
    width: 75%;
  }
  @media ${MediaBreakpoints.largeUp} {
    width: 50%;
  }
  img {
    animation: rotation 2s infinite linear;
    /* animation-direction: alternate-reverse; */
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(1, end);
    &.rotate {
      rotate: 5deg;
      animation-name: rotateLeft;
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @keyframes rotateLeft {
      20%   { transform: rotate(0.25turn); }
      40%  { transform: rotate(0.5turn); }
      60%  { transform: rotate(0.75turn); }
      80%  { transform: rotate(0.5turn); }
      100% { transform: rotate(0.25turn); }
    }
    @keyframes rotateRight {
      0%   { transform: rotate(0); }
      25%  { transform: rotate(-45deg); }
      50%  { transform: rotate(-45deg); }
      75%  { transform: rotate(-45deg); }
      100% { transform: rotate(0); }
    }
    z-index: 1;
  }
  a {
    padding: 1rem;
    position: absolute;
    top: 50%;
  }
`;
export const TextWrapper = styled(Container)`
  height: auto;
  text-align: center;
  p {
    font-size: 1.25rem;
  }
`;

export const PageStyle = css`
  height: calc(100vh - 2rem* 2 - 100px);
`;
