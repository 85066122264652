import normalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import MediaBreakpoints from './mediaBreakpoints';
import { AnimatedBackgroundColor, AnimatedBackgroundLight } from './background';
import { fontWeight, fixedSizes, palette } from './constants';
import { ThemeType } from './theme';

// Primary and Secondary fonts.
const fontStyle = `
  font-family: "aktiv-grotesk", sans-serif;
  font-style: normal;
`;
const fontStyleWide = `
  font-family: "roc-grotesk", sans-serif;
  font-style: normal;
`;
const altFontStyle = `
  font-family: lust-sans, sans-serif;
  font-style: normal;
`;

// Primary font styles.
export const fontLight = `
  ${fontStyle}
  font-weight: ${fontWeight.light};
`;
export const fontNormal = `
  ${fontStyle}
  font-weight: ${fontWeight.normal};
`;
export const fontMedium = `
  ${fontStyle}
  font-weight: ${fontWeight.medium};
`;
export const fontSemiBold = `
  ${fontStyle}
  font-weight: ${fontWeight.semiBold};
`;
// Primary Wide font styles.
export const fontWideNormal = `
  ${fontStyleWide}
  font-weight: ${fontWeight.normal};
`;
export const fontWideMedium = `
  ${fontStyleWide}
  font-weight: ${fontWeight.medium};
`;
export const fontWideSemiBold = `
  ${fontStyleWide}
  font-weight: ${fontWeight.semiBold};
`;
// Secondary font styles.
export const altFontNormal = `
  ${altFontStyle}
  font-weight: ${fontWeight.normal};
`;
export const altFontMedium = `
  ${altFontStyle}
  font-weight: ${fontWeight.medium};
`;
export const altFontSemiBold = `
  ${altFontStyle}
  font-weight: ${fontWeight.semiBold};
`;

interface Props {
  menuOpen?: boolean;
}

const GlobalStyle = createGlobalStyle<Props>`
${normalize};
* { 
margin: 0;
padding: 0;
box-sizing: border-box;
}
html {
  font-size: 16px;
  @media ${MediaBreakpoints.mediumUp} {
    font-size: 17px;
  }
  @media ${MediaBreakpoints.largeUp} {
    font-size: 18px;
  }
}
body {
  ${(props) => {
    switch (props.theme.type) {
      case ThemeType.GradientLight:
        return AnimatedBackgroundLight;
      case ThemeType.GradientColor:
        return AnimatedBackgroundColor;
      case ThemeType.Dark:
        return 'background-image: linear-gradient(to bottom, #404040, #121212 100%);';
      case ThemeType.White:
        return `background-color: ${palette.blanco};`;
      case ThemeType.Purple:
        return 'background-color: #EFD9FF';
      default:
        return '';
    }
  }};
  ${fontNormal};
  color: ${(props) => props.theme.primary.textColor};
  min-height: 100vh;
  ${(props) => {
    if (props.menuOpen) {
      return 'overflow-y: hidden;';
    }
    return '';
  }};
  #root {
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Left and right had: 1.625rem; */
    padding: ${fixedSizes.bodyVerticalPadding} ${fixedSizes.bodyHorizontalPadding};
    overflow: hidden;
    height: auto;
    width: 100vw;
    @media ${MediaBreakpoints.largeUp} {
      /* height: 100vh; */
      justify-content: space-around;
      overflow-x: auto;
      padding: 0 ${fixedSizes.bodyHorizontalPadding};
    }
  }
  .vertically-aligned {
    margin-top: auto;
    margin-bottom: auto;
  }
  .no-decoration {
    text-decoration: none;
  }
  p {
    ${fontLight}
    font-size: 18px;
    line-height: 1.44;
    @media ${MediaBreakpoints.largeUp} {
      line-height: 1.36;
    }
    a {
      color: ${(props) => props.theme.primary.textColor};
      font-weight: ${fontWeight.semiBold}
    }
  }
  ul {
    margin: 0 0 1rem 0;
    li {
      font-size: 18px;
      margin: 1rem 0 1rem 1rem;
      list-style-position: outside;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
`;
export default GlobalStyle;
