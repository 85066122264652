import styled, { css } from 'styled-components';
import Container from 'components/container';
import MediaBreakpoints from '../../styled/mediaBreakpoints';
import { fontWeight } from '../../styled/constants';

const AboutStyle = css`
  .about {
    &__heading {
      font-size: 45px;
      margin-top: 3rem;
      @media ${MediaBreakpoints.largeUp} {
        font-size: 60px;
        margin-top: 5.25rem;
      }
      @media ${MediaBreakpoints.extraLargeUp} {
        margin-top: 6rem;
      }
    }
    p {
      font-weight: ${fontWeight.medium};
    }
    b, strong {
      font-weight: ${fontWeight.bold};
    }
    h3 {
      font-size: 20px;
      font-weight: ${fontWeight.medium};
    }
  }
`;

export const ImagesWrapper = styled(Container)`
  img:nth-child(odd) {
    rotate: 5deg;
    animation-name: rotateLeft;
  }
  img:nth-child(even) {
    rotate: -5deg;
    animation-name: rotateRight;
  }
  img {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: steps(1, end);
    @keyframes rotateLeft {
      0%   { transform: rotate(0); }
      50%  { transform: rotate(5deg); }
      100% { transform: rotate(0); }
    }
    @keyframes rotateRight {
      0%   { transform: rotate(0); }
      50%  { transform: rotate(-5deg); }
      100% { transform: rotate(0); }
    }
  }
`;

export default AboutStyle;
