import { useState, useEffect } from 'react';
import { subscribe } from 'utils/events';
import { StyledButton } from './styles';

const FloatingCookie = () => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(false);
    const banner = document.getElementById('cookie-banner');
    if (banner) banner.style.display = 'flex';
  };

  useEffect(() => {
    if (localStorage.getItem('cookies-consent')) {
      setVisible(true);
    }
    subscribe('cookies-consent', () => setVisible(true));
  }, []);

  return (
    <StyledButton style={{ opacity: visible ? 1 : 0, visibility: visible ? 'visible' : 'hidden' }} onClick={handleClick}>
      <span>🍪</span>
    </StyledButton>
  );
};

export default FloatingCookie;
