import PageWrapper from 'components/pageWrapper';
import { DefaultTheme } from 'styled-components';
import { useEffect } from 'react';
import Button from 'components/button';
import Container from 'components/container';
import Image from 'components/image';
import Img01 from 'resources/cookies/01.png';
import Img02 from 'resources/cookies/02.png';
import Img03 from 'resources/cookies/03.png';
import Img04 from 'resources/cookies/04.png';
import Img05 from 'resources/cookies/05.png';
import Img06 from 'resources/cookies/06.png';
import { useDocumentTitle } from 'utils/pageUtils';
import { ImagesWrapper, PageStyle, TextWrapper } from './styles';
import { colorTheme } from '../../styled/theme';

// We define the props that will receive the setTheme
type Props = {
  setTheme: React.Dispatch<React.SetStateAction<DefaultTheme>>;
};

export default function Cookies(props: Props): JSX.Element {
  const images = [
    Img01,
    Img02,
    Img03,
    Img04,
    Img05,
    Img06,
  ];
  let counter = 0;
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    const imgElement = event.target as HTMLImageElement;
    counter += 1;
    if (counter === (images.length - 1)) imgElement.style.zIndex = '0';
    if (counter < images.length) {
      imgElement.src = images[counter];
      // imgElement.classList.remove('rotate');
    }
  };

  useEffect(() => {
    useDocumentTitle('Y estas cookies?');
    props.setTheme(colorTheme);
  });
  return (
    <PageWrapper heightAuto={false} cssStyling={PageStyle}>
      <Container flexDirection="column">
        {/* Contenido */}
        <Container flexDirection="column" align="center" responsive={false} spaceGap={0}>
          {/* Texto */}
          <TextWrapper
            flexDirection="column"
            justifyContent="center"
            fontFamily="primaryWide"
            align='center'
          >
            <p>
            ¡Hey! ¿Qué tal esas cookies? Cómete la última para ver mi web. 😉
            </p>
          </TextWrapper>
          {/* Cookie & CTA */}
          <ImagesWrapper flexDirection="column" justifyContent="flex-start" spaceGap={5}>
            <Image
              // className='rotate'
              disableLazyLoad={true}
              imgAlt="Anita Acosta 01"
              imgSrc={Img01}
              onclickHandler={handleClick}
            />
            <Button
              label="CHECK MY PROFILE!"
              link="/portfolio"
              variant="primary"
              size='lg'
            ></Button>
          </ImagesWrapper>
        </Container>
      </Container>
    </PageWrapper>
  );
}
